import React from 'react'

const Dataviz2 = () => {
  return (
    <div   id="dataviz2">
      <iframe 
      title="ecommerce_analysis" 
      width="100%"
      height="750px" 
       
      src="https://app.powerbi.com/view?r=eyJrIjoiMWFiOWFjODMtOGMxYi00YjE2LTk3ZTYtOGRhNGEwNTI1ZWQ5IiwidCI6ImVhOWI2OTg3LWFjZDctNDk4ZC05ZDdkLTU5NTk4ZjFjMGRlOCJ9" 
      frameborder="0" 
      allowFullScreen="true"></iframe>
    </div>
  )
}

export default Dataviz2
