import React, { useState } from "react";
import { Tab, Tabs, TabPane, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Secteurs() {
  const {t} = useTranslation()
  const [activeTab, setActiveTab] = useState("tabone");

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <section className="skills" id="activites">
      <div className="max-width">
        <h2 className="title">{t('activities.title')}</h2>
        <div className="skills-content">
          <Tabs activeKey={activeTab} onSelect={handleTabChange}>
            <Tab eventKey="tabone" title={t('activities.sous-title1')} className="tab">
              <TabContent
                title="D2I INSIGHTS"
                description={t('activities.bank.description1')} className="infos"/>
              <TabContent
                title="D2I PREDICT"
                description={t('activities.bank.description2')} />
              <TabContent
                title="D2I DEV"
                description={t('activities.bank.description3')} />
            </Tab>
            <Tab className="tab" eventKey="tabtwo" title="PME">
              <TabContent
                title="D2I INSIGHTS"
                description={t('activities.pme.description1')}/>
              <TabContent
                title="D2I PREDICT"
                description={t('activities.pme.description2')}
              />
              <TabContent
                title="D2I DEV"
                description={t('activities.pme.description3')}
              />
            </Tab>
            <Tab className="tab" eventKey="tabthree" title="Retail">
                <TabContent  
                    title="D2I INSIGHTS"
                    description={t('activities.retail.description1')}
                />
                  <TabContent  
                    title="D2I PREDICT"
                    description={t('activities.retail.description2')}
                />
                  <TabContent  
                    title="D2I DEV"
                    description={t('activities.retail.description3')}/>

            </Tab>
            <Tab className="tab" eventKey="tabfour" title="Telecommunications">
                <TabContent  
                    title="D2I INSIGHTS"
                    description={t('activities.telecommunication.description1')}
                />
                  <TabContent  
                    title="D2I PREDICT"
                    description={t('activities.telecommunication.description2')}
                />
                  <TabContent  
                    title="D2I DEV"
                    description={t('activities.telecommunication.description3')}/>

            </Tab>
            {/* Ajoutez des onglets supplémentaires ici pour les autres secteurs */}
          </Tabs>
        </div>
      </div>
    </section>
  );
}

const TabContent = ({ title, description }) => (
  <div className="tab-content">
    <Card>
      <Card.Body>
        <Card.Title className="title">{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  </div>
);

export default Secteurs;
