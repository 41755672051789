import React from 'react'

const Dataviz1 = () => {
  return (
    <div  id="dataviz1" >
      <iframe 
      title="churn_analysis_updated"
      width="100%"
      height="750px" 
      
       src="https://app.powerbi.com/view?r=eyJrIjoiNzk3YzhjZDItODg3MS00ZmZmLTkyNGYtNmY2MGE0NmRkNzBiIiwidCI6ImVhOWI2OTg3LWFjZDctNDk4ZC05ZDdkLTU5NTk4ZjFjMGRlOCJ9&pageName=d952f03511aacff94d25" 
       frameborder="0" allowFullScreen="true">
       </iframe>
    </div>
  )
}

export default Dataviz1
