import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Card } from "react-bootstrap";


function About({ language }) {
  const { t } = useTranslation();
  /*const [typedText1, setTypedText1] = useState("");
  const [currentIndex1, setCurrentIndex1] = useState(0);
  const [typedText2, setTypedText2] = useState("");
  const [currentIndex2, setCurrentIndex2] = useState(0);

  const about1Subtitle = t("about.about1.subtitle");
  const about2Subtitle = t("about.about2.subtitle");

  useEffect(() => {
    const interval1 = setInterval(() => {
      if (currentIndex1 < about1Subtitle.length) {
        setTypedText1((prevText) => prevText + about1Subtitle[currentIndex1]);
        setCurrentIndex1((prevIndex) => prevIndex + 1);
      } else {
        setTypedText1("");
        setCurrentIndex1(0);
      }
    }, 200);

    const interval2 = setInterval(() => {
      if (currentIndex2 < about2Subtitle.length) {
        setTypedText2((prevText) => prevText + about2Subtitle[currentIndex2]);
        setCurrentIndex2((prevIndex) => prevIndex + 1);
      } else {
        setTypedText2("");
        setCurrentIndex2(0);
      }
    }, 200);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, [about1Subtitle, currentIndex1, about2Subtitle, currentIndex2]);
  */
  return (
    <section className="about" id="about">
      <Container>
        <h2 className="title">{t("about.title")}</h2>
        <Row>
          <Col md={6}>
            <Card>
              <Card.Img variant="top" src="close-up-businessman-with-digital-tablet.jpg" />
              <Card.Body>
                <Card.Title className="card-title-bold">
                  {t("about.about1.title")} <span className="typing">{t("about.about1.subtitle")}</span>
                </Card.Title>
                <Card.Text>{t("about.about1.description")}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Img variant="top" src="6151069.jpg" />
              <Card.Body>
                <Card.Title className="card-title-bold">
                  {t("about.about2.title")} <span className="typing">{t("about.about2.subtitle")}</span>
                </Card.Title>
                <Card.Text>{t("about.about2.description")}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default About;
