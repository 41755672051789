import React, { useState, useEffect } from 'react';
import { Button , Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import Dataviz1 from "./Dataviz1";
import Dataviz2 from "./Dataviz2";
function Home() {
  const { t } = useTranslation();
  const [typedText, setTypedText] = useState('');
  const [textIndex, setTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  const [showIframe ,setShowIframe] = useState(false)
  const handleClose = () => setShowIframe(false)
  const handleShow = () => setShowIframe(true)
  const [showIframe1 ,setShowIframe1] = useState(false)
  const handleClose1 = () => setShowIframe1(false)
  const handleShow1 = () => setShowIframe1(true)

  const handleClick = () => {
   /* const contentClicked = document.querySelector("#contact");
    if (contentClicked) {
      contentClicked.scrollIntoView({ behavior: "smooth" });
    }*/ 
    const currentLanguage = i18n.language
    window.location.href = `https://datadevinsights.com/scoring?lang=${currentLanguage}`;
  };

  useEffect(() => {
    const text = [t('home.text1'), t('home.text2'), t('home.text3')];
    const interval = setInterval(() => {
      if (charIndex < text[textIndex].length) {
        setTypedText((prevText) => prevText + text[textIndex][charIndex]);
        setCharIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(interval); // Stop the interval
        setTimeout(() => {
          setTypedText(''); // Clear the typed text after 1 second
          setCharIndex(0); // Reset the charIndex
          setTextIndex((prevIndex) => (prevIndex + 1) % text.length); // Move to the next text
        }, 1000);
      }
    }, 100);

    return () => clearInterval(interval); // Cleanup the interval
  }, [charIndex, textIndex]);

  return (
    <section className="home" id="home" >
      <div className="max-width">
        <div className="home-content">
          <div className="text-2">{t('home.title')}</div>
          <div className="text-3">{t('home.subtitle')} <span className="typing">{typedText}</span></div>
      
       <Button onClick={handleClick} className='btn1 button'>{t('home.cta')}</Button>
        <div style={{height :"10px"}}></div>
       <Button className='btn2 button ' onClick={handleShow}>
            {t('services.service1.btn1')}
        </Button> 
        <div style={{height :"10px"}}></div>
        <Button className='btn1 button' onClick={handleShow1}>
            {t('services.service1.btn2')}
        </Button> 
        
          
          
        
        </div>
      </div>
      <Modal show={showIframe} onHide={handleClose} size="lg"   centered>
            <Modal.Header closeButton>
              <Modal.Title>Churn Monitoring</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div style={{ width: '100%', height: '750px'  , maxHeight :"1048px" }}>
            <Dataviz1 />
          </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                close 
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showIframe1} onHide={handleClose1} size="lg"   centered>
            <Modal.Header closeButton>
              <Modal.Title>Sales Performance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div style={{ width: '100%', height: '750px'  , maxHeight :"1048px" }}>
            <Dataviz2 />
          </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                close 
              </Button>
            </Modal.Footer>
          </Modal>
    </section>
  );
}

export default Home;
