import React from "react";
import { Container, Row, Col, Card,Button , Modal } from "react-bootstrap";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faChartLine, faPaintBrush, faCode } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useState } from 'react'

function Services() {
  const {t} = useTranslation()
  const [voirPlus1  , setVoirPlus1] = useState(false)
  const [voirPlus2  , setVoirPlus2] = useState(false)
  const [voirPlus3  , setVoirPlus3] = useState(false)

  const addElllipse = (text , maxLength) => {
    if (text.lentgh === maxLength) return text 
    return text.slice(0 , maxLength) + "..."
  }
  return (
    <section className="services" id="services">
      <Container>
        <h2 className="title">{t('services.title')}</h2>
        <Row className="serv.content">
          <Col md={4}>
            <Card className="card_center">
            <Card.Img variant="top" src="prediction.jpg" alt="Votre image"  className="img" />
              <Card.Body className="card_body">
                <Card.Title>{t('services.service1.title')}</Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                  {voirPlus1?t('services.service1.description'):addElllipse(t('services.service1.description'), 200)}
                 </Card.Text> 
                 <Button 
                variant="primary"
                 onClick={()=>setVoirPlus1(!voirPlus1)}
                 style={{backgroundColor:"#147ca6" , border:"none"}}>{voirPlus1?t('button.voirmoins'):t('button.voirplus') }</Button>
                  
              </Card.Body>
            </Card>
          </Col>
         
          <Col md={4}>
            <Card className="card_center">
            <Card.Img variant="top" src="6151069.jpg" alt="Votre image" className="img" />
              <Card.Body>
                <Card.Title>{t('services.service2.title')}</Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                {voirPlus2?t('services.service2.description'):addElllipse(t('services.service2.description'), 200)}
                </Card.Text>
                <Button
                 variant="primary" 
                 onClick={()=>setVoirPlus2(!voirPlus2)} 
                 style={{backgroundColor:"#147ca6" , border:"none"}}>{voirPlus2?t('button.voirmoins') : t('button.voirplus')}</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="card_center">
              <Card.Body>
              <Card.Img variant="top" src="dev.jpg" alt="Votre image" className="img"  />
                
                <Card.Title>{t('services.service3.title')}</Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                {voirPlus3?t('services.service3.description'):addElllipse(t('services.service3.description'), 200)}
                 </Card.Text>
                 <Button
                  variant="primary"
                  onClick={()=>setVoirPlus3(!voirPlus3)}
                  style={{backgroundColor:"#147ca6" , border:"none"}}>{voirPlus3?t('button.voirmoins'): t('button.voirplus')}</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Services;
