

import './App.css';
import Header  from "./components/Header"
import Home from './components/Home';
import Secteurs from './components/Secteurs';
import "bootstrap/dist/css/bootstrap.min.css"
import About from './components/About';
import Contact from './components/Contact';
import Services from './components/Services';
import {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import {i18n} from './i18n'
import Footer from './components/Footer';
import ModalLanguage from './components/Modal';
function App() {
  const [language, setLanguage] = useState('fr');
  const { t, i18n } = useTranslation();
  const [showLanguagePopup , setShowLanguagePopup ] = useState(false)
 
  const changeLanguage = (selectedLanguage) => {
    console.log("button" , selectedLanguage , "is clicked")
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    setShowLanguagePopup(false)
  };
  useEffect(() => {
    setShowLanguagePopup(true)
  } , [])
  return (
    <div className="App">
      {showLanguagePopup && ( 
        <ModalLanguage
          isOpen={showLanguagePopup}
          onClose={() => setShowLanguagePopup(false)}
          onChangeLanguage={changeLanguage}
        />
      )}
      <header id="header">
        <Header  language={language} setLanguage={changeLanguage} />
      </header>
      
      <Home  />
      <About language={language} />
      <Services  />
      <Secteurs />
      <Contact  />
      <Footer />
    </div>
  );
}

export default App;
