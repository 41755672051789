import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";


function ModalLanguage({isOpen , onClose ,  onChangeLanguage}){
    const [selectedLanguage , setSelectedLanguage] = useState('fr')
    const { t} = useTranslation();
    
    const handleLanguageChange = (language) => {
        setSelectedLanguage(language)
        onChangeLanguage(language)
        onClose();
    }
    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className="custom-modal">
            <h2>Select your language</h2>
            <div className="button-container">
                <div className="btn-anglais">
                    <img src="english.jpg.jpg" alt="english" style={{width:"45px" , height:"28px" , paddingRight:"5px" , borderRadius:"50%"}}/>
                    <button as="button" onClick={() => handleLanguageChange('en')}>{t('english')}</button>
                </div>
                <div className="btn-francais"> 
                    <img src="french.jpg" alt="francais" style={{width:"45px" , height:"28px" , paddingRight:"5px" , borderRadius:"50%"}}/>
                    <button  as="button" onClick={() => handleLanguageChange('fr')}>{t('french')}</button>
                </div>
                
            </div>
          
        </Modal>
    )
    
}

export default ModalLanguage ; 