import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

function Contact() {
  const [formState, setFormState] = useState({});
  const [message, setMessage] = useState({ text: "", type: "" });
  const form = useRef();
  const { t } = useTranslation();

  const handleChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const resetForm = () => {
    setFormState({})
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_4r9zqns", "template_zxfsnqq", form.current, {
        publicKey: "gj_6UOFtw_tmKJqLk",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setMessage({ text: "Mail envoyé avec succès.", type: "success" });
          setTimeout(() => setMessage({ text: "", type: "" }), 3000); // Réinitialise le message après 3 secondes
          resetForm();
        },
        (error) => {
          console.log("FAILED...", error.text);
          setMessage({ text: "Une erreur s'est produite lors de l'envoi du mail.", type: "error" });
        }
      );
  };

  return (
    <section className="contact" id="contact">
      <Container className="contact-content">
        <Row className="w-100">
          <h1 className="title">{t("contact.title")}</h1>
          <Card className="form">
            <Card.Body>
              <Form ref={form} onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicName">
                  <Form.Label>{t('placeholder.name')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t('placeholder.nom')}
                    name="user_name"
                    value={formState.user_name || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t('placeholder.email')}
                    name="user_email"
                    value={formState.user_email || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicMessage">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t('placeholder.message')}
                    name="message"
                    value={formState.message || ""}
                    onChange={handleChange}
                  />
                  <Button as="button" variant="success" type="submit" className="btn">
                   {t('button.envoyer')}
                  </Button>
                </Form.Group>
                {message.text && (
                  <p style={{ color: message.type === "error" ? "red" : "green" }}>{message.text}</p>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </section>
  );
}

export default Contact;
