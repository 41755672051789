import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
    return (
        <footer>
            <Container>
                <span><span className="far fa-copyright"></span> 2024 All rights reserved.</span>
            </Container>
        </footer>
    );
}

export default Footer;
